import User from '@/models/User';

export const DUMMY_USERS = [
  // ////
  // NONE
  new User({
    username: undefined,
    displayName: 'None - Do not Override',
    groups: [],
  }),

  // ////////////
  // INTERNAL - CALL CENTRE
  new User({
    username: 'USER_A1',
    displayName: 'Internal - MMU Call Centre Manager',
    groups: ['MyBlueFleet - MMU Call Centre Manager'],
  }),
  new User({
    username: 'USER_A2',
    displayName: 'Internal - MMU Call Centre Team Leader',
    groups: ['MyBlueFleet - MMU Call Centre Team Leader'],
  }),
  new User({
    username: 'USER_A3',
    displayName: 'Internal - MMU Call Centre Team Member',
    groups: ['MyBlueFleet - MMU Call Centre Team Member'],
  }),

  // //////////////////////
  // INTERNAL - MERCHANT
  new User({
    username: '11218762',
    displayName: 'Internal - MMU Merchant Manager',
    groups: ['MyBlueFleet - MMU Merchant Manager'],
  }),
  new User({
    username: '11218762',
    displayName: 'Internal - MMU Merchant Team Leader',
    groups: ['MyBlueFleet - MMU Merchant Team Leader'],
  }),
  new User({
    username: '11218762',
    displayName: 'Internal - MMU Merchant Team Member',
    groups: ['MyBlueFleet - MMU Merchant Team Member'],
  }),

  // ////////////
  // INTERNAL - COMPLIANCE
  new User({
    username: 'USER_C1',
    displayName: 'Internal - MMU Compliance Manager',
    groups: ['MyBlueFleet - MMU Compliance Manager'],
  }),
  new User({
    username: 'USER_C2',
    displayName: 'Internal - MMU Compliance Team Leader',
    groups: ['MyBlueFleet - MMU Compliance Team Leader'],
  }),
  new User({
    username: 'USER_C3',
    displayName: 'Internal - MMU Compliance Team Member',
    groups: ['MyBlueFleet - MMU Compliance Team Member'],
  }),

  // ////////////
  // INTERNAL - PAYMENTS
  new User({
    username: 'USER_D1',
    displayName: 'Internal - MMU Payments Manager',
    groups: ['MyBlueFleet - MMU Payments Manager'],
  }),
  new User({
    username: 'USER_D2',
    displayName: 'Internal - MMU Payments Team Leader',
    groups: ['MyBlueFleet - MMU Payments Team Leader'],
  }),
  new User({
    username: 'USER_D3',
    displayName: 'Internal - MMU Payments Team Member',
    groups: ['MyBlueFleet - MMU Payments Team Member'],
  }),

  // ////////////
  // INTERNAL - CARD OPERATIONS
  new User({
    username: 'USER_D1',
    displayName: 'Internal - MMU Operations Team Member',
    groups: ['MyBlueFleet - MMU Operations Team Member'],
  }),

  // ////////////
  // INTERNAL - FUND OPERATIONS
  new User({
    username: 'USER_D1',
    displayName: 'Internal - Fund Operations Team Leader',
    groups: ['MyBlueFleet - Fund Operations Team Leader'],
  }),
  new User({
    username: 'USER_D2',
    displayName: 'Internal - Fund Operations Team Member',
    groups: ['MyBlueFleet - Fund Operations Team Member'],
  }),

  // ////////////
  // EXTERNAL - MERCHANT
  new User({
    username: '11218762',
    displayName: 'External - Merchant Superuser',
    groups: ['MyBlueFleet - Merchant Superuser'],
  }),
  new User({
    username: '11218762',
    displayName: 'External - Merchant Manager',
    groups: ['MyBlueFleet - Merchant Manager'],
  }),
  new User({
    username: '11218762',
    displayName: 'External - Merchant Team Member',
    groups: ['MyBlueFleet - Merchant Team Member'],
  }),

  // ////////////
  // EXTERNAL - CUSTOMER
  new User({
    username: '16976827',
    displayName: 'External - Customer Superuser',
    groups: ['MyBlueFleet - Customer Superuser'],
  }),
  new User({
    username: 'USER_F2',
    displayName: 'External - Customer Manager',
    groups: ['MyBlueFleet - Customer Manager'],
  }),
  new User({
    username: 'USER_F3',
    displayName: 'External - Customer Team Member',
    groups: ['MyBlueFleet - Customer Team Member'],
  }),
];

export const DUMMY_SR_STATES = {

};
