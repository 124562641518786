<template>
  <v-container class="fill-height">
    <v-row align="center"
           justify="center">
      <v-card color="secondary"
              width="350"
              class="mx-auto elevation-10">
        <v-card-text>
          <span class="text-h3 white--text">Authenticating, please wait...</span>
          <v-progress-linear indeterminate
                             color="white"
                             class="mt-5" />
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  components: {},

  data() {
    return {};
  },

  created() {
    this.authenticate({ authCode: this.$route.query.code, errorCode: this.$route.query.error });
  },

  methods: {
    ...mapActions('session', ['authenticate']),
  },
};
</script>
