<template>
  <v-container class="fill-height"
               fluid>
    <v-row align="center"
           justify="center">
      <v-col cols="12">
        <v-alert v-if="envMessage"
                 :color="getEnvColor()"
                 dark
                 class="mx-auto elevation-5"
                 max-width="650px">
          This application is running in {{ environment }} Mode
        </v-alert>
        <sb-card title="Sign In"
                 class="mx-auto"
                 max-width="650px">
          <v-row>
            <v-col cols="12"
                   sm="4"
                   class="mx-auto">
              <v-img src="/img/logos/logo_badge.png"
                     alt="SB_Badge"
                     class="mx-auto"
                     :max-width="isMobile ? '150px' : '300px'" />
            </v-col>
            <v-col :cols="isMobile ? 12 : 1"
                   class="text-center">
              <v-divider :vertical="!isMobile" />
            </v-col>
            <v-col cols="12"
                   md="7">
              <v-row class="text-center">
                <v-col cols="12"
                       class="mb-10">
                  <div class="text-h4 mx-auto">
                    Welcome to My Bluefleet
                  </div>
                  <div class="text-caption mx-auto">
                    Fleet {{ $route.meta.userType | startCase }} Login
                  </div>
                  <div class="mx-auto" />
                </v-col>
              </v-row>
              <v-row align="center"
                     justify="center">
                <!-- TEMP SOLUTION - Only visible to Dev and Localhost-->
                <v-col v-if="isDevelopment"
                       cols="12"
                       class="py-0">
                  <sb-select v-model="selectedDummyUser"
                             :items="dummyUsers"
                             return-object
                             item-text="displayName"
                             item-value="username"
                             label="Simulate User" />
                </v-col>

                <v-col cols="12"
                       class="pb-0 text-center">
                  <v-btn color="secondary"
                         large
                         depressed
                         :loading="authLoading"
                         @click="loginUser">
                    Login
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <template slot="actions">
            Version: {{ version }}
          </template>
        </sb-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import crypto from 'crypto';
import cryptoRandomString from 'crypto-random-string';
import { mapActions } from 'vuex';
import { mapWaitingActions, mapWaitingGetters } from 'vue-wait';

import { base64URLEncode, sha256 } from '@/utils';
import { DUMMY_USERS } from '@/config/dummyData';

export default {
  data() {
    return {
      user() {
        return this.$AuthService.getUser();
      },
      version: process.env.VUE_APP_VERSION,

      selectedDummyUser: '',
      dummyUsers: [],
    };
  },

  computed: {
    ...mapWaitingGetters({
      authLoading: ['authLoading'],
    }),

    envMessage() {
      return this.environment !== 'Production';
    },
  },

  created() {
    this.dummyUsers = DUMMY_USERS;

    // eslint-disable-next-line prefer-destructuring
    this.selectedDummyUser = this.dummyUsers[0];
  },

  methods: {
    ...mapActions('session', ['login']),

    ...mapWaitingActions('session', {
      login: 'authLoading',
    }),

    loginUser() {
      let { userType } = this.$route.meta;

      // Store the userType for later use
      localStorage.setItem('userType', userType);

      // TEMP OVERRIDE UNTIL CUSTOMER USERTYPE IS IMPLEMENTED
      if (userType === 'customer') {
        userType = 'merchant';
      }

      // Generate a random nonce and store it
      const nonce = cryptoRandomString({ length: 32 });
      sessionStorage.setItem('nonce', nonce);

      // Generate the verifier and code challenge
      const verifier = base64URLEncode(crypto.randomBytes(32));
      const challenge = base64URLEncode(sha256(verifier));

      // Build the auth url
      const authUrl = process.env.VUE_APP_AUTH_URL.replace('{{redirectUrl}}', window.location.origin)
        .replace('{{code_challenge}}', challenge)
        .replace('{{nonce}}', nonce);
      const staffUrl = authUrl.replace('{{userType}}', userType);

      // Store the verifier for use in Authenticate.vue
      sessionStorage.setItem('verifier', verifier);

      // TEMPORARILY STORE OVERRIDE USER
      this.$store.commit('session/tempUser', undefined);
      if (this.selectedDummyUser.username !== undefined) {
        this.$store.commit('session/tempUser', this.selectedDummyUser);
      }

      // Redirect to the Authentication Authority login
      window.location.href = staffUrl;
    },

    getEnvColor() {
      let color;

      switch (this.environment) {
        case 'Development':
          color = 'secondary';
          break;
        case 'Testing':
          color = 'warning';
          break;
        case 'Staging':
          color = 'deep-purple darken-1';
          break;
        default:
          color = 'Not set';
          break;
      }

      return color;
    },
  },
};
</script>
